.csr {
  &-intro {
    &__photo {
      margin-top: 10px;
      text-align: center;
    }
  }
  &-activity {
    &-ttl {
      &--secondary {
        @include mq(xl) {
          margin-bottom: 40px;
          
        }
        @include mq(lg) {
//          font-size: 3.8rem;
//          margin-bottom: 7.46vw;
        }
      }
      &-module {
        &--primary {
          padding-bottom: 9px;
          font-size: 2rem;
          @include mq(lg) {
            font-size: 3rem;
          }
        }
      }
    }
    &-box {
      &:nth-of-type(n+2) {
        margin-top: 60px;
        @include mq(lg) {
          margin-top: 10vw;
        }
      }
    }
  }

}
