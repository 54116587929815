.box {
  &-border-top {
    background: #fff;
    padding-top: 35px;
    padding-bottom: 55px;
    @include mq(lg) {
      padding: 10vw 2vw;
    }
    &--red {
      @extend .box-border-top;
      border-top: 1px $red-d solid;
    }
  }
}

table {
  width: 100%;
  th,td {
    vertical-align: top;
  }
}
