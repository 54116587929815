.footer {
  padding: 20px 0 30px;
  background: $red-d;
  color: #fff;
  font-size: 1.3rem;
  @include mq(lg) {
    position: relative;
    padding: 8vw 0;
    font-size: 2.4rem;
  }
  &-btn {
    position: absolute;
    z-index: 50;
    @include mq(xl) {
      bottom: calc(100% - 30px);
    }
    @include mq(lg) {
      right: 2.6vw;
      top: -6.6vw;
    }
    &__inner {
      display: block;
      position: relative;
      top: 0;
      right: 0;
      width: 80px;
      height: 80px;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      background: $red-d;
      @include mq(lg) {
       width: 15vw; 
       height: 15vw; 
      }
    }
    &:after {
      position: absolute;
      top: 30px;
      left: calc(50% - 3px);
      transform: rotate(-45deg) translateY(-50%);
      content: "";
      width: 15px;
      height: 15px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      @include mq(lg) {
        top: 5.6vw;
        width: 10px;
        height: 10px;
        left: 48%;
      }
    }
    &.fixed {
      position: fixed;
      bottom: 20px;
      top: auto;
      &:after {
        top: 50%;
      }
    }
  }
  a {
    color: #fff;
    @include mq(lg) {
      display: block;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  &-wrap {
    @include mq(xl) {
      position: relative;
      display: flex;
      z-index: 70;
    }
    @include mq(lg) {
      padding-bottom: 8vw;
    }
  }
  &-nav {
    display: flex;
    @include mq(xl) {
     flex-basis: 600px; 
    }
    @include mq(lg) {
      flex-wrap: wrap;
    }
    &__items {
      @include mq(lg) {
        flex-basis: 50%;
      }
      //      font-weight: bold;
      &:nth-child(n+2) {
        @include mq(xl) {
          margin-left: 29px;
        }
      }
      &:nth-child(n+3) {
        @include mq(lg) {
          margin-top: 4.26vw;
        }
      }
    }
    &-child {
      margin-top: 10px;
      &__items {
        font-size: 1.2rem;
        font-weight: normal;
        &-link {}
      }
    }
  }
  &-outside {
    @include mq(xl) {
      margin: 0 14px;
      padding: 0 16px;
      border: 1px #fff solid;
      border-top: 0;
      border-bottom: 0;
      flex-basis: 147px;
    }
    @include mq(lg) {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      margin-top: 8.4vw;
    }
    &__items {
      @include mq(lg) {
        flex-basis: 50%;
      }
      &-link {
        position: relative;
        display: block;
        .ico-window {
          @include mq(xl) {
            position: absolute;
            text-align: right;
            width: 10px;
            height: 10px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          @include mq(lg) {
            margin-left: 2.6vw;
            width: 2.4vw;
            height: 2.4vw;
          }
        }
      }
    } 
  }
  &-info {
    font-size: 1.2rem;
    @include mq(xl) {
      flex-basis: 175px;
    }
    @include mq(xl) {
      z-index: 100;
    }
    &__name {
      font-size: 1.6rem;
    }
    &__detail {
      
    }
    &__tel {
      font-size: 1.8rem;
    }
  }
  .copy {
    margin-top: 40px;
    @include mq(lg) {
      margin-top: 0;
      text-align: center;
    }
    @include mq(lg) {
      small {
        padding-top: 3.3vw;
        display: block;
        border-top: 1px #fff solid;
        
      }
    }
  }
}
