.business {
  &-performance {
    &-ttl {
      margin-top: 50px;
    }
  }
  &-award-list {
      th {
        @include mq(xl) {
          text-align: right;
        }
      }
    td {
      @include mq(xl) {
        padding-left: 60px;
        
      }
    }
    @include mq(lg) {
      th,td {
        display: block;
      }
      th {
        width: 20%;
        display: inline-block;
      }
      tr {
        &:not(:first-child) {
          th {
            padding-top: 10px;
          }
        }
    
      }
    }
  }
}

.room {
  &-management {
    border: 1px #ddd solid;
    background: #fff;
    @include mq(lg) {
      font-size: 2.6rem;
    }
    th,
    td {
      border: 1px #ddd solid;
      vertical-align: middle;
    }
    thead {
      th,td {
        font-weight: bold;
      }
    }
    tbody {
      .room-management-comment {
        font-size: 1.8rem;
        color: #ff0101;
        font-weight: bold;
        @include mq(lg) {
          font-size: 2.8rem;
        }
      }
    .room-management-roomNum {
      text-align: left;
      @include mq(xl) {
        padding-left: 40px;
      }
    }
    }
    &-prefecture,
    &-roomNum,
    &-categoryRoom,
    &-comment
    {
      padding: 16px 0;
      @include mq(lg) {
        padding: 10px 0;
      }
    }
    &-prefecture,
    &-roomNum {
      width: 120px;
      @include mq(lg) {
        min-width:70px;
      }
    }
    &-categoryRoom,
    &-comment {
      padding-left: 40px;
      width: 370px;
      @include mq(lg) {
        padding-left: 10px;
        padding-right: 10px;
        min-width:200px;
      }
    }
    &-prefecture {
      text-align: center;
    }
    &-comment {
    }
    &-roomNum {
      @include mq(xl) {
      text-align: center;
      }
       @include mq(lg) {
        padding-left: 10px;
      }
   }

  }
}
