body {
  &:before {
    @include mq(xl) {
      position: fixed;
      top: 0;
      width: 100%;
      height: 100vh;
      background: rgba(#1b1b1b, 0.7);
      content: '';
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      -webkit-transform: translateY(-10%);
      -ms-transform: translateY(-10%);
      transform: translateY(-10%);
      opacity: 0;
      visibility: hidden;
      z-index: 100;
      left: 220px;
      width: 200px;
      -webkit-transform: translateX(-10%);
      -ms-transform: translateX(-10%);
      transform: translateX(-10%);
    }
  }
}

.megaMenuOpen {
  &:before {
    @include mq(xl) {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
      
    }
  }
}

.header {
  @include mq(xl) {
    display: block;
    width: 220px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: #FFF;
    border-right: 1px #d9d9d9 solid;
    z-index: 100;
  }
  @include mq(lg) {
//    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    
  }
  &-body {
    @include mq(lg) {
      position: absolute;
      padding: 2.6vw 0 10.6vw;
      background: rgba(#1b1b1b, 0.6);
      width: 100%;
      left: 0;
      height: calc(100vh - 16vw);
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      z-index: 200;
      top: 16vw;
    }
    
  }
  &-intro {
    background: #fff;
    @include mq(lg) {
      padding: 2.26vw 6.6vw 2.26vw 2.2vw;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      height: 16vw;
      border-bottom: 1px #ddd solid;
    }
  }
  &-logo {
    @include mq(xl) {
      padding: 0 20px;
      margin: 40px 0;
    }
    @include mq(lg) {
      flex-basis: 33.3vw;
    }
  }
  &-nav {
    @include mq(lg) {
      padding: 0 2.6vw;
    }
    &-list {
      &__items {
        font-size: 1.5rem;
        padding: 0 20px;
        @include mq(lg) {
          padding: 0;
          font-size: 3rem;
          border-bottom: 1px #eee solid;
        }
        &-link {
          display: block;
          padding: 20px 0;
          @include mq(xl) {
            border-bottom: 1px #eee solid;
          }
          @include mq(lg) {
            padding: 5.3vw 2.6vw;
          }
          &:hover {
            @include mq(xl) {
              color: $red-d;
              
            }
          }
        }
        &.mega-menu {
          .header-nav-list__items-link {
            @include mq(lg) {
              padding: 5.3vw 2.6vw 4vw;
            }
          }
        }
      }
      a {
        @include mq(lg) {
          color: #fff;
        }
      }
    }
  }
  &-contact-list {
    padding: 60px 20px 0;
    @include mq(lg) {
      padding: 8vw 6.6vw 0;
    }
    &__items {
      &:nth-of-type(n+2) {
        margin-top: 20px;
        @include mq(lg) {
          margin-top: 5.3vw;
        }
      }

    }
  }
  &-btn {
    &__inner {
      width: 40px;
      height: 30px;
      display: block;
      border-radius: 5px;
      @include mq(lg) {
        height: 34px;
        width: 6.25vw;
        height: 24px;
      }
    }
    &:hover {
      cursor: pointer;
    }
    i {
      display: block;
      width: 40px;
      height: 1px;
      background: $red-d;
      transition: background 0.5s;
      position: relative;
      left: 0;
      top: 15px;
      transition: .5s;
      @include mq(lg) {
        height: 2px;
        width: 6.25vw;
        top: 10px;
      }
      &:before,
      &:after {
        content: "";
        display: block;
        width: 40px;
        height: 1px;
        background: $red-d;
        position: absolute;
        transform: rotate(0deg);
        transition: all 0.3s !important;
        @include mq(lg) {
          height: 2px;
          width: 6.25vw;
        }
      }
      &:before {
        transform: translateY(15px);
        @include mq(lg) {
          transform: translateY(10px);
        }
      }
      &:after {
        transform: translateY(-15px);
        @include mq(lg) {
          transform: translateY(-10px);
        }
      }
    }
    @at-root {
      .menu-open {
        .header-btn__inner {
          i {
            background: #fff;
            &:after {
              transform: translateY(0px) rotate(-45deg);
            }
            &:before {
              transform: translateY(0px) rotate(45deg);
            }
          }
        }
      }
    }
  }
  
}

.mega-menu {
  position: relative;
  &-list {
    @include mq(xl) {
      padding-top: 20px;
      display: block !important;
      position: absolute;
      top: 0;
      left: 100%;
      width: 200px;
      opacity: 0;
      transform: translateX(-10%);
      visibility: hidden;
      transition: 0.3s;
      z-index: 100;
    }
    &__items {
        &:nth-child(n+2) {
          padding-top: 20px;
        }

      &-link {
        display: block;
        padding: 0 10px 0 30px;
        color: #fff;
        font-size: 1.3rem;
        @include mq(lg) {
          position: relative;
          padding: 3vw 2.6vw 3vw 24.1vw;
          font-size: 2.8rem;
          &:after {
            content:"";
            position: absolute;
            top: 50%;
            right: 3.6vw;
            width: 1.8vw;
            height: 1.8vw;
            border-top: 1px solid #fff;
            border-right: 1px solid #fff;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg) translateY(-50%);
           
          }
        }
      }
      &:last-child {
        .mega-menu-list__items-link {
          @include mq(lg) {
            padding-bottom: 3.3vw;
          }
        }
      }
    }
  }
  &:hover {
    .mega-menu-list {
      transform: translateX(0);
      visibility: visible;
      opacity: 1;

    }

  }
}
