.contact {
  &-intro {
    &__lead {
    text-align: center;
      p {
        & + p {
          margin-top: 30px;
        }
      }

    }
    
  }
  &-body {
    margin: 80px auto 0;
    @include mq(xl) {
      width: 800px;
    }
    @include mq(lg) {
      margin-top: 10.6vw;
      padding: 5.3vw 0;
      background: rgba(#fff,.6);
    }
    th,td {
      vertical-align: top;
      @include mq(lg) {
        display: block;
      }
    }
    th {
      width: 238px;
      padding: 17px 0 17px 30px;
      @include mq(lg) {
        width: 100%;
        background: none;
        padding: 0;
      }
    }
    td {
      padding: 10px 15px;
      @include mq(lg) {
        padding: 0;
      }
    }
    tr {
      @include mq(xl) {
        border-top: 1px #ddd solid;
      }
      &:last-child {
        @include mq(xl) {
          border-bottom: 1px #ddd solid;
        }
      }
      &:nth-child(n+2) {
        th {
          @include mq(lg) {
            padding-top: 3.06vw;
            
          }
        }
      }
    }
    &-error {
      &-box {
      font-style: normal;
      color: $red-d;
      font-size: 1.4rem;
        @include mq(lg) {
          font-size: 2.6rem;
        }

    }
    &-comment {
      font-style: normal;
      color: $red-d;
      font-size: 1.4rem;
      font-weight: bold;
        @include mq(lg) {
          font-size: 2rem;
        }
    }
    }
  }
  &-table {
    &-note {
      margin-bottom: 8px;
      font-size: 1.3rem;
      @include mq(lg) {
        font-size: 2rem;
      }
    }
  }
  &-require-mark {
    color: #96292b;
    font-size: 1.2rem;
    font-weight: bold;
    @include mq(lg) {
      font-size: 2.4rem;
    }
  }
  &-confirm-btn {
    margin: 40px auto 0;
    @include mq(lg) {
      margin: 8vw auto 0;
    }
//    &:hover {
//      cursor: pointer;
//    }
  }
  &-btn {
    &-list {
      display: flex;
      justify-content: center;
      &__items {
        &:nth-child(n+2) {
          margin-left: 10px;
        }
        @include mq(lg) {
          
        }
      }
    }
  }
  &-finsh {
    &-btn {
      &__items {
        margin: 20px auto 0;
        padding: 10px 0; 
      }
    }
  }
}