@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin btn-red($radius) {
  border-radius: $radius;
  background: $red-d;
  color: #fff;
  transition: $transition;
  border: 1px $red-d solid;
  &:hover {
    border: 1px $red-d solid;
    color: $red-d;
    background: #fff;
    .fill-white {
      fill:$red-d;
    }
  }
} 
@mixin btn-brown($radius) {
  border-radius: $radius;
  border: 1px #502f1b solid;
  color: #502f1b;
} 

@mixin btn-white($radius) {
  border-radius: $radius;
  border: 1px #fff solid;
  color: #fff;
}

@mixin placeholderColor($color) {
    &:placeholder-shown {
        color: $color;
    }
    &::-webkit-input-placeholder {
        color:$color;
    }
    &:-moz-placeholder {
        color:$color;
        opacity: 1;
    }
    &::-moz-placeholder {
        color:$color;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color:$color;
    }
}

