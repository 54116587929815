.wrapper {
  &.top {
    @include mq(lg) {
      background: url(../img/top/bg_top_01_sp.jpg) no-repeat top center;
      background-size: 100%;
    }
  }
}

.top {
  &-sec {
    padding: 60px 0;
    @include mq(lg) {
      padding: 10.6vw 0;
    }
    &-lg {
      padding: 100px 0;
      @include mq(lg) {
        padding: 16vw 0;
      }
    }
  }
  &-hero {
    position: relative;
    height: 778px;
    background: url(../img/top/hero_pc.png) no-repeat top center;
    @include mq(lg) {
      background: url(../img/top/hero_sp.png) no-repeat top center;
      height: 125vw;
      background-size: cover;
    }
    &__catch {
      @include mq(xl) {
        position: relative;
        margin: 0 auto;
        width: 1020px;
      }
      @include mq(lg) {
        position: absolute;
        top: 15.3vw;
        left: 9.06vw;
        width: 68.26vw;
//        width: 55.26vw;
      }
      img {
        display: block;
        @include mq(xl) {
          position: absolute;
          top: 85px;
          left: 0;
        }
      }
    }

  }
  &-sec-01 {
    position: relative;
    text-align: center;
    padding: 145px 0 100px;
    @include mq(xl) {
      background: url(../img/top/bg_top_01_pc.jpg) no-repeat bottom center;
      &:before {
        content:"";
        position: absolute;
        background: url(../img/top/bg_top_00_pc.png) no-repeat 0 0;
        width: 408px;
        height: 448px;
        top: -266px;
        left: 41%;
        transform: translateX(-50%);
      }
    }
    @include mq(lg) {
//      background: url(../img/top/bg_top_01_sp.jpg) no-repeat bottom center;
      padding: 28vw 0 15vw;
    }
    &__ttl {
      position: relative;
      &:after {
        content: "";
        background: url(../img/top/bg_sec01_01_pc.png) no-repeat 0 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 280px;
        height: 286px;
        @include mq(lg) {
          width: 54vw;
          height: 55vw;
          background-size: contain;
        }
      }

    }
    &__lead {
      margin-top: 115px;
      line-height: 2;
      @include mq(lg) {
        margin-top: 10vw;
      }
    }
  }
  &-sec-02 {
    background: #fff;
    &-wrap {
      position: relative;
      justify-content: space-between;
      @include mq(lg) {
        display: block;
      }
    }
    &__ttl {
      font-family: $mincho;
      font-size: 3rem;
      @include mq(lg) {
        text-align: center;
        font-size: 3.8rem;
      }
    }
    &__lead {
      margin-top: 38px;
      font-size: 1.6rem;
      @include mq(lg) {
        margin-top: 7.46vw;
        font-size: 2.8rem;
      }
    }
    &-txt {
      @include mq(xl) {
        flex-basis: 406px;
        max-width: 406px;
      }
    }
    &-photo {
      margin-top: 2.6vw;
    }
    &-btn {
      padding: 5px 0;
      @include mq(xl) {
        position: absolute;
        bottom: 0;
        left: 20px;
        bottom: 27px;
      }
      @include mq(lg) {
        margin: 8vw auto 0;
      }
    }
  }
  &-sec-03 {
    background: url(../img/top/bg_top_02_pc.jpg) no-repeat bottom center / cover;
    @include mq(lg) {
      background: url(../img/top/bg_top_02_sp.jpg) no-repeat top center;
      background-size: cover;
    }
    &-list {
      justify-content: space-between;
      @include mq(lg) {
        flex-direction: column;
        align-items: center;
      }
      &__items {
        text-align: center;
        @include mq(xl) {
          flex-basis: 312px;
          max-width: 312px;
        }
        &:nth-child(n+2) {
          @include mq(lg) {
            margin-top: 10.5vw;
          }
        }
        &-catch {
          margin-top: 35px;
          font-size: 2.6rem;
          font-family: $mincho;
          @include mq(lg) {
            margin-top: 4.3vw;
            font-size: 4.2rem;
          }
        }
        figure {
          flex-basis: 312px;
          height: 312px;
          @include mq(lg) {
            width: 58.6vw;
            height: auto;
          }

        }
      }
    }
  }
  &-sec-04 {
    &-list {
      @include mq(xl) {
        justify-content: center;
        
      }
      @include mq(lg) {
        flex-direction: column;
        align-items: center;
      }
      &__items {
        @include mq(xl) {
          flex-basis: 460px;
          max-width: 460px;
        }
        @include mq(lg) {
          width: 100%;
        }
        &:nth-child(2) {
          margin-left: 40px;
          @include mq(lg) {
            margin-left: 0;
            margin-top: 8vw;
          }
        }
      }
    }
  }
  &-banner {
    padding: 40px 0 60px;
    @include mq(lg) {
      padding: 6.67vw 0 10.6vw;
    }
    &__image {
      text-align: center;
    }
    &__link {
      display: block;
      width: 100%;
    }
  }
}

.fb-page {
  display:block !important;
  width:100%;
  max-width:500px;
  margin-left:auto;
  margin-right:auto;
  height:405px;
}


.top-sec-05 {
  padding: 20px 0;
  @include mq(lg) {
    // padding: 0;
    // padding-left: 20/750*100vw;
    // padding-right: 20/750*100vw;
  }
}

.top-sec-05-inner {
  padding: 40px 50px;
  background: #fff;
  @include mq(xl)  {

  }
  @include mq(lg) {
    padding: 20px 4.53vw 30px;
  }
}

.top-sec-05__heading {
  font-size: 2.6rem;
  font-family: Georgia, "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "Noto Serif JP", serif;
  text-align: center;
  font-weight: bold;
  @include mq(lg)  {
    font-size: 4.2rem;
  }
}

.top-sec-05__lead {
  margin: 5px 0 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid #b5b5b5;
  text-align: center;
  @include mq(lg) {
    font-size: 2.7rem;
    margin: 4px 0 15px;
  }
}

.top-sec-05__box {
  @include mq(xl) {
    display: flex;
  }
}

.top-sec-05__image {
  @include mq(xl) {
    flex: 0 0 296px;
    margin-right: 25px;
  }
}

.top-sec-05__text {
  font-size: 1.4rem;
  @include mq(lg) {
    margin-top: 10px;
    font-size: 2.4rem;
  }
  .font-bold {
    font-weight: bold;
    // margin-bottom: 12px;
    font-size: 1.6rem;
    @include mq(lg) {
      font-size: 2.8rem;
    }
  }
  .link {
    margin-top: 25px;
    display: inline-block;
    color: $red-d;
    font-weight: bold;
    @include mq(lg) {
      margin-top: 10px;
      font-size: 2.6rem;
    }
    span {
      border-bottom: 1px solid currentcolor;
      &:hover {
        border-bottom: 1px solid transparent;
      }
    }
    img {
      width: 16px;
      vertical-align: middle;
      margin-left: 4px;
    }
    & + p {
      margin-top: 20px;
      margin-left: -25px;
      font-size: 1.6rem;
      @include mq(lg) {
        margin-top: 10px;
        margin-left: 20px;
        font-size: 2.8rem;
      }
    }
  }
}

.top-sec-05__text-heading {
  font-size: 2.2rem;
  color: $red-d;
  // margin-bottom: 15px;
  font-weight: bold;
  @include mq(lg) {
    font-size: 3.6rem;
  }
}

.top-sec-05__text-heading--sub {
  &:nth-of-type(n+2) {
    margin-top: 10px;
  }
}

.top-sec-05__text-description {

}

