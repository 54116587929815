@charset "UTF-8";

@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP|Noto+Serif+JP');
//settings
@import "./_settings/minireset";
@import "./_settings/variables";
@import "./_settings/mixin";

//layout
@import "_layout/_base.scss";
@import "_layout/_footer.scss";
@import "_layout/_header.scss";
@import "_layout/_nav.scss";

//plugin


//module
@import "_modules/_animation.scss";
@import "_modules/_box.scss";
@import "_modules/_btn.scss";
@import "_modules/_form.scss";
@import "_modules/_module.scss";
@import "_modules/_svg.scss";

//style


//style
@import "_pages/_business.scss";
@import "_pages/_company.scss";
@import "_pages/_contact.scss";
@import "_pages/_csr.scss";
@import "_pages/_for-companies.scss";
@import "_pages/_top.scss";